export const routes = [
  {
    path: '/account-settings/:tab?',
    name: 'accountSettings',
    component: () => import('@/app/modules/lk/views/accountSettings.vue'),
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/app/modules/lk/views/SignIn'),
  },
];
