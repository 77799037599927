import { createRouter, createWebHashHistory } from 'vue-router';

import { routes as digitalCommisions } from '@/app/modules/DigitalCommisions/router/index';
import { routes as eventRoutes } from '@/app/modules/Events/router/index';
import { routes as exportPotential } from '@/app/modules/ExportPotential/router/index';
import { routes as internationalTrade } from '@/app/modules/InternationalTrade/router/index';
import { routes as marketAccessRoutes } from '@/app/modules/MarketsAccess/router/index';
// Роуты функциональных модулей
import { routes as perechenMer } from '@/app/modules/MeasuresTtrStd/router/index';
import { routes as memberNavigator } from '@/app/modules/MemberNavigator/router/index';
import { routes as authRoutes, registerGuards as registerAuthGuards } from '@/app/modules/auth/router/index';
import { routes as lk } from '@/app/modules/lk/router/index';
import { routes as news } from '@/app/modules/news/router/index';

import { menuActive } from '@/common/mixins/menu-active.js';
import runtimeConfig from '@/common/runtime-config';

const routes = [
  { path: '/', name: 'home', component: () => import('@/app/modules/Home/Home') },
  ...authRoutes,
  ...perechenMer,
  ...memberNavigator,
  ...marketAccessRoutes,
  ...internationalTrade,
  ...exportPotential,
  ...lk,
  ...digitalCommisions,
  ...eventRoutes,
  ...news,

  {
    path: '/analitcs-countries',
    name: 'analitcs-countries',
    //redirect: runtimeConfig.apiBaseUrl + '/#/Country'
    beforeEnter() {
      window.location.replace(runtimeConfig.apiBaseUrl + '/#/Country');
    },
  },
];

const router = createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  routes,
});

registerAuthGuards(router);

router.afterEach(async () => {
  await router.isReady();
  menuActive(router.currentRoute._value.href);
});

export default router;
