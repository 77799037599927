export function setNSITnvedByIDs(state, res) {
  const { data = [] } = res;
  state.nsiTnvedByIDs = data;
}

export function setNSITnvedByIDsDelete(state, id) {
  state.nsiTnvedByIDs = state.nsiTnvedByIDs.filter((i) => +i.id !== id);
}

export function setNSITnved(state, res) {
  const { data = [] } = res;
  state.nsiTnvedSearch = data.items.map((i) => ({ id: i.id, text: i.name }));
}
