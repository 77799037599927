// подсветка активных пунктов для меню, в котором подпункты не совпадают с родительскими пунктами(по иерархии пути)
export function menuActive(routerHref) {
  var classFirstLevel = '.header_menu-main-itemLink',
    classLinkActive = 'router-link-active';
  document.querySelectorAll(classFirstLevel).forEach(function (firstLevel) {
    if (routerHref?.split('/')[1] !== firstLevel.getAttribute('href')?.split('/')[1]) {
      // удаляет все классы у первого уровня ссылок,
      // если href не совпадает с текущей страницей
      firstLevel.classList.remove(classLinkActive);
    }
    let ul = firstLevel.parentNode.nextElementSibling,
      li = ul?.querySelector('.' + classLinkActive),
      a = li?.parentNode.parentNode.previousElementSibling.querySelector(classFirstLevel);
    // добавляет класс на первый уровень только если ссылка в подменю
    // активна и имеет класс .router-link-active, то есть не null
    a?.classList.add(classLinkActive);
  });
}
