<template>
  <footer class="footer" v-if="hideClutterPrintContent">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__inner-col">
          <img class="symbol" src="@/assets/img/logoFooter.png" alt="Герб" />
          <!-- <p>
            Официальный сайт Минэкономразвития России. 
            Свидетельство о регистрации СМИ Эл № ФС77-61745
            <br/>
            от 30 апреля 2015 г.
          </p> -->
        </div>

        <div class="footer__inner-col">
          <ButtonComponent
            class="mt-8"
            btnType="news"
            @click="dialogWindow = !dialogWindow"
            title="Подписка на новости"
          />
        </div>

        <div class="footer__inner-col">
          <div class="footer__inner-col-block">
            <SocialMedia></SocialMedia>
          </div>

          <div class="footer__inner-col-block">
            <p>Служба технической поддержки:</p>
            <p>
              <a href="mailto:supportved@economy.gov.ru" target="_blank">supportved@economy.gov.ru</a>
            </p>
          </div>
        </div>
      </div>

      <ModalComponent @close="dialogWindow = false" v-model="dialogWindow" title="Подписка на новости" :width="500">
        <SubscriptionNews v-on:close="closeEditor" />
      </ModalComponent>
    </div>
  </footer>
</template>

<script>
  import SocialMedia from './SocialMedia.vue';
  import SubscriptionNews from './subscriptionNews.vue';
  import ButtonComponent from './ui/ButtonComponent.vue';
  import ModalComponent from './ui/ModalComponent.vue';

  export default {
    // TODO Заменить по возможности
    // eslint-disable-next-line
    name: 'App-footer',
    components: {
      ButtonComponent,
      ModalComponent,
      SubscriptionNews,
      SocialMedia,
    },
    data() {
      return {
        dialogWindow: false,
      };
    },
    computed: {
      hideClutterPrintContent() {
        return !this.$store?.state?.navigator?.oidc?.hideClutterPrintContent;
      },
    },
    methods: {
      closeEditor() {
        this.dialogWindow = false;
      },
    },
  };
</script>

<style lang="sass">
  @import "./styles/footer.sass"
</style>
