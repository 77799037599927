export const ACTIONS_NAME = {
  getCountries: 'getCountries', // запрос на получение списка стран
  getCountriesAgreements: 'getCountriesAgreements',
  getCountriesGroupAgreements: 'getCountriesGroupAgreements',
};

export const MUTATIONS_NAME = {
  updateCountriesAgreements: 'updateCountriesAgreements',
  updateCountriesGroupAgreements: 'updateCountriesGroupAgreements',
};

export const GETTERS_NAME = {
  getSelectCountries: 'getSelectCountries', // получение списка стран
  getListCountriesAgreements: 'getListCountriesAgreements',
  getListCountriesGroupAgreements: 'getListCountriesGroupAgreements',
};
