import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/restrictionmeasure/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/restrictionmeasure/${id}`);
  },
  searchMap: function (request) {
    return axios.get('/api/mpkProject/search', { params: request });
  },
  countryList: function () {
    return axios.get('/api/restrictionmeasure/countryList');
  },
  tradeItems: function () {
    return axios.get('/api/restrictionmeasure/tradeItems');
  },
};
