<template>
  <label class="switch" @click="active = false" :class="className">
    <input type="checkbox" :checked="active" @change="switchHandler()" />
    <span class="slider"></span>
    <span class="one" @click="googleTranslateElement('ru')" :class="{ active: !active }">Ru</span>
    <span class="two" @click="googleTranslateElement('en')" :class="{ active: active }">En</span>
    {{ active }}
  </label>
</template>

<script>
  export default {
    // TODO: По возможности исправить
    // eslint-disable-next-line
    name: 'switchComponent',
    props: {
      // Закоментировал после линтера
      // checked: {
      //   type: Boolean,
      //   default: true,
      // },
      className: {
        type: String,
        default: '',
      },
      // textTrue: {
      //   type: String,
      //   default: 'Товары',
      // },
      // textFalse: {
      //   type: String,
      //   default: 'Услуги',
      // },
    },
    data() {
      return {
        active: false,
        currentLang: 'ru',
      };
    },
    methods: {
      switchHandler() {
        if (this.currentLang == 'ru') {
          this.active = null;
        } else {
          this.active = true;
        }
        this.$emit('switchHandler', this.active);
      },
      googleTranslateElement(item) {
        window.google.translate.TranslateElement(
          {
            pageLanguage: 'ru|en',
            includedLanguages: 'en,ru',
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: false,
          },
          'google_translate_element',
        );
        if (this.currentLang != item) {
          this.currentLang = item;
          if (item != 'ru') {
            setTimeout(() => {
              let getFrameOfGoogle = document.querySelector('iframe.skiptranslate');
              let innerDoc = getFrameOfGoogle.contentDocument;
              let innerDocItems = innerDoc.querySelectorAll('a');
              for (let i = 0; i < innerDocItems.length; i++) {
                let itemOfGoogleFrame = innerDocItems[i];
                if (itemOfGoogleFrame.value === item) {
                  itemOfGoogleFrame.click();
                }
              }
            }, 500);
          } else {
            this.$router.go();
          }
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .one,
  .two {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    line-height: 17px;
    color: #0093ba;
  }
  .one.active,
  .two.active {
    position: absolute;
    font-weight: bold;
    font-size: 12px;
    color: white;
    line-height: 14px;
  }
  .one {
    left: 4px;
  }
  .two {
    right: 7px;
  }
  .switch {
    // margin-left: 60px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 48px;
    height: 26px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #00b2aa;
    border-radius: 4px;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    top: 2.5px;
    left: 2px;
    bottom: 4px;
    background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 4px;
  }
  input:checked + .slider {
    background-color: white;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px white;
  }
  input:checked + .slider:before {
    transform: translateX(22px);
  }
</style>
