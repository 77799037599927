import { ACTIONS_NAME, MUTATIONS_NAME } from '@/app/shared/state/directoryNames';
import request from '@/app/shared/state/request';

export async function getCountriesAgreements(context, params = {}) {
  return await request.call(
    this,
    context,
    {
      url: '/api/country/search',
      mutate: MUTATIONS_NAME.updateCountriesAgreements,
      name: ACTIONS_NAME.getCountriesAgreements,
      requestAuth: true,
    },
    params,
  );
}

export async function getCountriesGroupAgreements(context, params = {}) {
  return await request.call(
    this,
    context,
    {
      url: '/api/countrygroup/search',
      mutate: MUTATIONS_NAME.updateCountriesGroupAgreements,
      name: ACTIONS_NAME.getCountriesGroupAgreements,
      requestAuth: true,
    },
    params,
  );
}
