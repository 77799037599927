export const routes = [
  {
    path: '/export-potential',
    name: 'export-potential',
    component: () => import('@/app/modules/ExportPotential/views/exportPotential.vue'),
    props: true,
  },
  {
    path: '/export-potential-map',
    name: 'app-export-potential-map',
    component: () => import('@/app/modules/ExportPotential/component/exportPotentialMap'),
    props: true,
  },
];
