import { authAxios } from '@/common/axios';

export default {
  search: function (request) {
    return authAxios.get('/api/countrygroup/search', { params: request });
  },
  find: function (id) {
    return authAxios.get('/api/countrygroup/' + id);
  },
  update: function (data) {
    if (data.id > 0) return authAxios.put('/api/countrygroup/' + data.id, data);
    else return authAxios.post('/api/countrygroup/', data);
  },
  delete: function (id) {
    return authAxios.delete('/api/countrygroup/' + id);
  },
};
