function mounted(el, binding) {
  el.clickOutsideEvent = function (event) {
    if (!(el === event.target || el.contains(event.target))) {
      binding.value(event, el);
    }
  };
  document.body.addEventListener('click', el.clickOutsideEvent);
}

function unmounted(el) {
  document.body.removeEventListener('click', el.clickOutsideEvent);
}

const directive = {
  mounted,
  unmounted,
};

export default directive;
