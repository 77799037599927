import axios from 'axios';

export default {
  create: function (request) {
    return axios.post('/api/requestinitiativettr', request);
  },
  update: function (request) {
    return axios.put('/api/requestinitiativettr', request);
  },
  getStatus: function (id) {
    return axios.get(`/api/requestinitiativettr/status/${id}`);
  },
  find: function (id) {
    return axios.get(`/api/requestinitiativettr/find/${id}`);
  },
};
