export const routes = [
  {
    path: '/digital-commisions/:type?',
    name: 'DigitalCommisions',
    component: () => import('@/app/modules/DigitalCommisions/views/DigitalCommisions.vue'),
  },
  {
    path: '/digital-commisions/:type/:action/:id',
    name: 'DigitalCommissionCard',
    component: () => import('@/app/modules/DigitalCommisions/views/DigitalCommissionCard.vue'),
  },
];
