<template>
  <div class="showmore-underline">
    <button v-if="currentPage < pageCount && pageCount > 1" v-on:click="showMore()">Показать еще</button>
  </div>

  <nav class="paggination__container">
    <div class="paggination__backBlock" v-bind:class="{ disabled: currentPage == 1 }">
      <p v-on:click="goToPage(1)">&lt;&lt;</p>
      <p v-on:click="goToPrev()">&lt;</p>
    </div>
    <ul class="paggination__listPages">
      <li
        v-bind:class="{ choosen: button.number === currentPage }"
        v-bind:key="button.number"
        v-for="button in buttons"
      >
        <p v-if="button.number" type="button" v-on:click="goToPage(button.number)">
          {{ button.number }}
        </p>
        <p v-if="button.isSpacer">...</p>
      </li>
    </ul>
    <div class="paggination__nextBlock" v-bind:class="{ disabled: currentPage == pageCount }">
      <p v-on:click="goToNext()">></p>
      <p v-on:click="goToPage(pageCount)">>></p>
    </div>
  </nav>
</template>

<script>
  function makeButtons(currentPage, pageCount) {
    const maxButtonCount = 7;
    const buttons = [];
    const middle = Math.ceil(maxButtonCount / 2);
    if (pageCount <= maxButtonCount) {
      // 1 2 3 4 5 6 7
      for (let i = 1; i <= pageCount; i++) {
        buttons.push({ number: i });
      }
    } else if (currentPage <= middle) {
      // 1 2 3 4 5 ... 8
      const to = maxButtonCount - 2;
      for (let i = 1; i <= to; i++) {
        buttons.push({ number: i });
      }
      buttons.push({ isSpacer: true });
      buttons.push({ number: pageCount });
    } else if (currentPage >= pageCount - (maxButtonCount - middle)) {
      // 1 ... 4 5 6 7 8
      buttons.push({ number: 1 });
      buttons.push({ isSpacer: true });
      const from = pageCount - maxButtonCount + 3;
      for (let i = from; i <= pageCount; i++) {
        buttons.push({ number: i });
      }
    } else {
      // 1 ... 4 5 6 ... 9
      buttons.push({ number: 1 });
      buttons.push({ isSpacer: true });
      const from = currentPage - middle + 3;
      const to = from + (maxButtonCount - 4);
      for (let i = from; i <= to; i++) {
        buttons.push({ number: i });
      }
      buttons.push({ isSpacer: true });
      buttons.push({ number: pageCount });
    }
    return buttons;
  }

  export default {
    // TODO Заменить по возможности
    // eslint-disable-next-line
    name: 'PageNav',
    props: {
      currentPage: Number,
      pageCount: Number,
    },
    data() {
      return {
        buttons: [],
      };
    },
    methods: {
      goToPage(pageNum) {
        this.$emit('change', pageNum);
      },
      showMore() {
        this.$emit('more', this.currentPage + 1);
      },
      goToPrev() {
        if (this.currentPage > 1) this.$emit('change', this.currentPage - 1);
      },
      goToNext() {
        if (this.currentPage < this.pageCount) this.$emit('change', this.currentPage + 1);
      },
    },
    watch: {
      currentPage: function (newValue /*, oldValue*/) {
        this.buttons = makeButtons(newValue, this.pageCount);
      },
      pageCount: function (newValue /*, oldValue*/) {
        this.buttons = makeButtons(this.currentPage, newValue);
      },
    },
    created() {
      this.buttons = makeButtons(this.currentPage, this.pageCount);
    },
  };
</script>

<style lang="scss">
  @import '@/assets/theme/default//global/variables.scss';
  @import '@/assets/theme/default//global/mixin.scss';
  .page-link {
    color: $gray-m-light !important;
  }
  // Пагинация
  .paggination {
    &__container,
    &__listPages,
    &__backBlock,
    &__nextBlock {
      display: flex;
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 38px;
        max-width: 38px;
        min-height: 38px;
        min-width: 38px;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        margin-bottom: 0px;
        cursor: pointer;
      }
    }
    &__container {
      margin-top: 12px;
      margin-bottom: 95px;
      justify-content: center;
    }
    &__listPages,
    &__backBlock,
    &__nextBlock {
      p {
        background: #0093ba;
      }
    }
    &__backBlock,
    &__nextBlock {
      p {
        margin-right: 3px;
        border-radius: 5px;
        &:last-of-type {
          margin-right: 0px;
        }
      }
    }
    &__listPages {
      padding: 0px 16px;
      li {
        margin-right: 3px;
        &:not(.choosen) {
          p {
            color: #ffffff98;
          }
        }
        &:last-of-type {
          margin-right: 0px;
          p {
            border-radius: 0px 5px 5px 0px;
          }
        }
        &:nth-of-type(1) {
          p {
            border-radius: 5px 0px 0px 5px;
          }
        }
      }
    }
    &__backBlock.disabled,
    &__nextBlock.disabled {
      p {
        opacity: 0.5;
      }
    }
  }
  @media (max-width: 767px) {
    .paggination__backBlock,
    .paggination__nextBlock {
      display: none;
    }
    .paggination__container p,
    .paggination__listPages p,
    .paggination__backBlock p,
    .paggination__nextBlock p {
      max-height: 30px;
      max-width: 30px;
      min-height: 30px;
      min-width: 30px;
    }
    .paggination__listPages {
      padding: 0px 6px;
    }
  }
</style>
