import CountryApi from '@/app/nsi/api/country';
import CountryGroupApi from '@/app/nsi/api/countryGroup';
import NsiApi from '@/app/nsi/api/nsi';

export default {
  state: {
    selectCountries: [],
    selectIndustries: [],
    selectCountriesGroup: [],
  },
  getters: {
    getSelectCountries(state) {
      return state.selectCountries;
    },
    getSelectCountriesGroup(state) {
      return state.selectCountriesGroup;
    },
    getSelectIndustries(state) {
      return state.selectIndustries;
    },
  },
  mutations: {
    updateCountries(state, countries) {
      state.selectCountries = countries;
    },
    updateCountriesGroup(state, countriesGroup) {
      state.selectCountriesGroup = countriesGroup;
    },
    updateIndustries(state, list) {
      state.selectIndustries = list;
    },
  },
  actions: {
    getCountries(ctx) {
      if (ctx.getters.getSelectCountries.length == 0) {
        CountryApi.search({ pageNumber: 1, pageSize: 0, isActive: true }).then((response) => {
          const countries = response.data.items.map((x) => ({ value: x.id, label: x.name, code: x.code }));
          ctx.commit('updateCountries', countries);
        });
      }
    },
    getCountriesGroup(ctx) {
      if (ctx.getters.getSelectCountriesGroup.length == 0) {
        CountryGroupApi.search({ pageNumber: 1, pageSize: 0, isActive: true })
          .then((response) => {
            const countries = response.data.items.map((x) => ({ value: x.id, label: x.name, code: x.code }));
            ctx.commit('updateCountriesGroup', countries);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    getIndustries(ctx) {
      NsiApi.search('nsiindustry', { pageNumber: 1, pageSize: 0, isActive: true }).then((response) => {
        const list = response.data.items.map((x) => ({ value: x.id, label: x.name }));
        ctx.commit('updateIndustries', list);
      });
    },
  },
};
