import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/macmaporgcustomsduties/search', { params: request });
  },
  countByCountry: function (request) {
    return axios.get('/api/macmaporgcustomsduties/countbycountry', { params: request });
  },
  averageDutyByCountry: function (request) {
    return axios.get('/api/macmaporgcustomsduties/averagebycountry', { params: request });
  },
  regulatorRequirements: function (request) {
    return axios.get('/api/macmaporgregulatoryrequirements/search', { params: request });
  },
};
