export const routes = [
  { path: '/perechen-mer-tamoj', component: () => import('@/app/modules/MeasuresTtrStd/views/PerechenMerTamojMain') },
  {
    name: 'perechen-mer-tamoj-vremen',
    path: '/perechen-mer-tamoj-vremen',
    component: () => import('@/app/modules/MeasuresTtrStd/views/PerechenMerTamojVremen'),
    props: {
      isSecond: false,
      pageTitle: 'Перечень единых мер таможенно-тарифного регулирования, применяемых на временной основе',
    },
  },
  {
    name: 'perechen-mer-netamoj-odnostoron',
    path: '/perechen-mer-netamoj-odnostoron',
    component: () => import('@/app/modules/MeasuresTtrStd/views/PerechenMerTamojOdnostoron'),
    props: {
      isSecond: false,
      pageTitle:
        'Перечень единых мер нетарифного регулирования, применяемых только в отношении товаров, происходящих с территории Российской Федерации',
    },
  },
  {
    name: 'list-of-protection-measures',
    path: '/list-of-protection-measures',
    component: () => import('@/app/modules/MeasuresTtrStd/views/ListOfProtectionMeasures'),
  },
  {
    name: 'feedback',
    path: '/feedback',
    component: () => import('@/app/modules/MeasuresTtrStd/views/InitiativeFeedback.vue'),
  },
  {
    path: '/measure/reqsinitiativesttr/edit/:id',
    name: 'RequestInitiativeTtrCard',
    component: () => import('../views/RequestInitiativeTtrCard'),
  },
  { path: '/protective-measures', component: () => import('@/app/modules/MeasuresTtrStd/views/ProtectiveMeasures') },
  {
    path: '/perechen-mer-tamoj-filltered',
    component: () => import('@/app/modules/MeasuresTtrStd/views/PerechenMerTamojFilltered'),
    name: 'PerechenMerTamojFilltered',
  },
  {
    name: 'perechen-mer-single-ttr',
    path: '/perechen-mer-single-ttr',
    component: () => import('@/app/modules/MeasuresTtrStd/views/PerechenMerTamojVremen'),
    props: {
      isSecond: true,
      pageTitle: 'Перечень односторонних мер таможенно-тарифного регулирования',
    },
  },
  {
    name: 'perechen-mer-single-netariff',
    path: '/perechen-mer-single-netariff',
    component: () => import('@/app/modules/MeasuresTtrStd/views/PerechenMerTamojOdnostoron'),
    props: {
      isSecond: true,
      pageTitle: 'Перечень односторонних мер нетарифного регулирования',
    },
  },
  {
    name: 'measure-search-results',
    path: '/measure/search-results',
    component: () => import('@/app/modules/MeasuresTtrStd/views/SearchResults'),
  },
];
