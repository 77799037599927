import axios from 'axios';

export default {
  get: function (request) {
    return axios.get('/api/exportpotential/upload', { params: request });
  },
  search: function (data) {
    let params = Object.keys(data)
      .map((param) => {
        if (param === 'ParrentsCodesNsiTnved') {
          return data[param].map((item) => param + '=' + item).join('&');
        }
        return param + '=' + data[param];
      })
      .join('&');
    return axios.get('/api/exportpotential/search?' + params);
  },
  getCountry: () => {
    return axios.get('/api/nsi/countryList', {});
  },
  getCountryWithPotentialProduct: () => {
    return axios.get('/api/exportpotential/getCountryList');
  },
  getCountryWithPotentialService: () => {
    return axios.get('/api/exportPotentialServiceItem/getCountryList');
  },
  getProductsListWithExportPotential: async (request) => {
    return axios.get('/api/exportpotential/getProductList?', { params: request });
  },
  getProductsList: async (request) => {
    return axios.get('/api/nsi/nsitnved/search', { params: request });
  },
  getServiceList: async () => {
    return axios.get('/api/exportPotentialServiceItem/getServiceList');
  },
  getServiceGraph: function (request) {
    return axios.get('/api/exportPotentialServiceItem/getGraph', { params: request });
  },
  searchService: function (data) {
    let params = Object.keys(data)
      .map((param) => {
        if (param === 'ParrentsCodesNsiTnved') {
          return data[param].map((item) => param + '=' + item).join('&');
        }
        return param + '=' + data[param];
      })
      .join('&');
    return axios.get('/api/exportPotentialServiceItem/search?' + params);
  },
};
