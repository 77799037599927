import Swal from 'sweetalert2';

export default {
  emptySelectOptionText: '-- не выбрано --',
  validatorMessageEmpty: 'Введите данные',
  pageSize: 10,
  messengerFileLimit: 5,
  messengerFileMaxSize: 10485760,
  confirmOptions: {
    delete: {
      title: 'Удаление',
      text: 'Вы действительно хотите удалить эту запись?',
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отмена',
      showCancelButton: true,
      icon: 'warning',
    },
    changeStatus: {
      title: 'Изменение статуса',
      text: 'Изменить статус этой записи?',
      confirmButtonText: 'Изменить',
      cancelButtonText: 'Отмена',
      showCancelButton: true,
      icon: 'warning',
    },
  },
  countryRussiaId: 235,
  countryRussiaName: 'РОССИЯ',
  countryGroupEAEC: 7,
  countryKNR: 108,
  mpkAssignemntStatusDoneId: 5,
  commonError: 'Произошла ошибка. Обратитесь в техническую поддержку.',
  commonSuccess: 'Данные успешно сохранены.',
  validationError: 'Проверьте правильность заполнения полей.',
  select2Settings: {
    language: 'ru',
    allowClear: true,
    matcher: function (params, data) {
      if (!params.term) return data;
      if (data.text.toLowerCase().startsWith(params.term.toLowerCase())) return data;
      else return null;
    },
  },
  select2SettingsMultiple: {
    multiple: true,
    // select2Settings
    language: 'ru',
    allowClear: true,
    matcher: function (params, data) {
      if (!params.term) return data;
      if (data.text.toLowerCase().startsWith(params.term.toLowerCase())) return data;
      else return null;
    },
  },
  yesNoList: [
    {
      id: null,
      text: 'Не выбрано',
    },
    {
      id: true,
      text: 'Да',
    },
    {
      id: false,
      text: 'Нет',
    },
  ],
  chatType: {
    private: 1,
    group: 2,
    room: 3,
    techSupport: 4,
  },
  entityStatus: {
    draft: 1,
    published: 2,
    archive: 3,
  },
  entityType: {
    employee: 8,
    news: 11,
  },
  organizationType: {
    foiv: 1,
    navigatorParticipant: 2,
    external: 3,
  },
  tradingOperation: {
    export: 1,
    import: 2,
  },
  userType: {
    internal: 1,
    external: 2,
  },
  tradeItemType: {
    tnved: 1,
    okved: 2,
  },
  organizationMinekId: 1,

  alert: Swal.mixin({
    //customClass: {
    //  confirmButton: 'btn btn-success',
    //  cancelButton: 'btn btn-danger'
    //},
    //buttonsStyling: false
  }),
  removeFromArr(arr) {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  },

  Permissions: {
    MpkCommissionAccess: 160,
    MpkCommissionEdit: 161,
    MpkPodgotovkaAccess: 17,
    MpkEventsAccess: 170,
    MpkEventsEdit: 171,
    MpkEventsTimelineEdit: 1710,
    MpkTaskAccess: 172,
    MpkTaskEdit: 173,
    MpkLibraryAccess: 174,
    MpkLibraryEdit: 175,
    MpkRequestsAccess: 176,
    MpkRequestsEdit: 177,
    MpkAssignmentAccess: 178,
    MpkAssignmentEdit: 179,
    MpkAssignmentViewAllFoiv: 1790,
    AccessEditableResponsibleEmployee: 1400,
  },
  finSectorNumbering: {
    0: '',
    1: 'тыс.',
    2: 'млн.',
    3: 'млрд.',
    4: 'трлн.',
    5: 'квдлн.',
  },

  langRu: {
    errorLoading: function () {
      return 'Невозможно загрузить результаты';
    },
    loadingMore: function () {
      return 'Загрузка данных…';
    },
    noResults: function () {
      return 'Совпадений не найдено';
    },
    searching: function () {
      return 'Поиск…';
    },
    removeAllItems: function () {
      return 'Удалить все элементы';
    },
    inputTooShort: function () {
      return 'Введите больше символов для начала поиска';
    },
  },
  DATE_FORMATS: {
    YYYY: 'YYYY', // 2023
    DD_MMMM: 'DD MMMM', // 23 Января
    'MM.YYYY': 'MM.YYYY', // 10.2023
    'MMM, YYYY': 'MMM, YYYY', // Янв, 2023
    MMMM_YYYY: 'MMMM YYYY', // Января (или Январь) 2023
    'DD.MM.YYYY': 'DD.MM.YYYY', // 23.10.2023
    'YYYY-MM-DD': 'YYYY-MM-DD', // 2023-10-23
    DD_MMMM_YYYY: 'DD MMMM YYYY', // 23 Января 2023
    'DD.MM.YYYY HH:mm': 'DD.MM.YYYY HH:mm', // 23.12.2023 01:10
    'DD.MM.yyyy HH:mm:ss': 'DD.MM.yyyy HH:mm:ss', // 23.12.2023 01:10:21
    'HH:mm': 'HH:mm', // 23:12
  },
  paramsFullListDefault: {
    pageNumber: 1,
    pageSize: 0,
    isActive: true,
  },
  initiativeStatuses: {
    processApplicantExport: 13,
    processApplicantImport: 14,
  },
};
