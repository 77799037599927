<template>
  <template v-if="!async">
    <Multiselect
      :id="selectId"
      :close-on-select="mode === 'single'"
      :clear-on-select="false"
      :searchable="true"
      :multiple-label="multiLabel"
      :options="optionsFilter"
      :mode="mode"
      :disabled="disabled"
      :preserve-search="mode !== 'single'"
      :hide-selected="false"
      :filterResults="true"
      noResultsText="Ничего не найдено."
      noOptionsText="Список пуст."
      :object="object"
      @search-change="setFilterOptions"
    />
  </template>
  <template v-else>
    <!-- в компоненте есть проблема, когда данные с сервера приходят пустые(например, пользователь неправильно ввел),
    то селект(и остальные тоже) ломается с ошибкой undefined в :label, то есть тут не хватает какой-то настройки, которая бы защищала -->
    <Multiselect
      :id="selectId"
      :options="optionsFilter"
      :mode="mode"
      :close-on-select="mode === 'single'"
      :clear-on-select="false"
      :searchable="true"
      :internal-search="false"
      :loading="isLoadingInternal"
      :label="label"
      :hide-selected="false"
      :show-no-results="true"
      :preserve-search="mode !== 'single'"
      :disabled="disabled"
      :filterResults="true"
      noResultsText="Ничего не найдено."
      noOptionsText="Список пуст."
      :multiple-label="multiLabel"
      @search-change="asyncFind"
      @close="onClose"
      :object="object"
    />
  </template>
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  export default {
    components: {
      Multiselect,
    },
    name: 'SelectComponent',
    emits: ['search', 'close'],
    props: {
      selectId: String,
      async: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        default: null,
        required: true,
      },
      mode: {
        required: false,
        default: 'single',
      },
      showSelectedCount: {
        type: Boolean,
        default: false,
      },
      object: {
        type: Boolean,
        default: false,
      },
      searchTimeout: {
        type: Number,
        default: 1000,
      },
      label: {
        type: String,
        default: 'label',
      },
      directUpdate: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isLoadingInternal: false,
        timerId: null,
        optionsFilter: null,
      };
    },
    watch: {
      options: {
        handler: function () {
          this.loadOptions();
        },
        deep: true,
      },
      isLoading: function () {
        this.isLoadingInternal = !!this.isLoading;
        if (!this.isLoadingInternal) {
          // workaround of strange behaviour: showing "No results found" even if results are fetched.
          let el = document.querySelector(`#${this.selectId} .multiselect-caret`);
          el.click();
          setTimeout(() => {
            el = document.querySelector(`#${this.selectId}`);
            el.focus();
          }, 100);
        }
      },
    },
    mounted() {
      this.loadOptions();
    },
    methods: {
      asyncFind(query) {
        if (!query) return;
        if (this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = null;
        }
        this.timerId = setTimeout(() => {
          // this.setFilterOptions(query);
          this.$emit('search', { query, filter: () => this.setFilterOptions(query) });
        }, this.searchTimeout);
      },
      setFilterOptions(query) {
        if (!query) {
          this.optionsFilter = this.options;
        }
        if (this.options?.length) {
          // this.optionsFilter = this.options
          // if(!this.options[0]?.code.length && !query.includes()){

          // this.optionsArr = this.options.map((el,i) => {
          //   console.log(Object.keys(el));
          //   return { index: i, label: el[this.label].toLowerCase(), id: el.value }
          // })
          // this.optionsFilter = this.optionsArr.sort((a,b) => {
          //   return b.label.startsWith(query.toLowerCase()) -
          //          a.label.startsWith(query.toLowerCase())
          // })

          this.optionsFilter = this.options.filter((option) =>
            option.label.toLowerCase().includes(query.toLowerCase()),
          );

          /**
           * !три строчки выше заменил код, который выше, т.к. не корректно отображает селект
           */
          // this.optionsArr = this.options;

          // this.optionsFilter = this.optionsArr.sort((a, b) => {
          //   return (
          //     b[this.label].toLowerCase().startsWith(query.toLowerCase()) -
          //     a[this.label].toLowerCase().startsWith(query.toLowerCase())
          //   );
          // });
          // }
        }
      },
      loadOptions() {
        if (this.directUpdate) {
          this.optionsFilter = this.options;
        } else if (this.optionsFilter === null && this.options.length > 0) {
          this.optionsFilter = this.options;
        }
      },
      onClose() {
        this.$emit('close');
      },
      multiLabel(value) {
        if (this.showSelectedCount) {
          if (value?.length > 1) {
            return `Выбрано: ${value.length}`;
          }
        }
        let label = '';
        value.forEach((item, index) => {
          if (index) {
            label = label + `, ${item.label}`;
          } else {
            label = item.label;
          }
        });
        return label;
      },
    },
  };
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
  :root {
    // Modal
    --g-dialog-overlay-bg: linear-gradient(270deg, rgba(0, 178, 170, 0.5) 0%, rgba(0, 120, 200, 0.5) 100%);
    // Multiselect
    --ms-tag-bg: #d3dfe6;
    --ms-tag-font-weight: 400;
    --ms-tag-color: #231f20;
    --ms-tag-remove-radius: 50%;
    --ms-option-font-size: 14px;
    --ms-bg: #fbfbfb;
    --ms-caret-color: transparent;
  }
  .multiselect {
    font-size: 14px;
    box-sizing: initial;

    &.is-active.is-open {
      border-color: $light-grey-5 !important;
    }

    &-search {
      border: none !important;
    }

    &--multiple {
      .multiselect-multiple-label {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: auto;
        max-height: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .multiselect.error {
    border: 1px solid red;
  }
  .multiselect-clear {
    margin: 0px 15px 0 0;
    padding: 0;
  }
  .multiselect-tag-remove {
    background-color: #787f8f;
    padding: 2px;
    margin: 0 6px 0 10px;
  }
  .multiselect-tag-remove-icon {
    background-color: #d3dfe5;
  }
  .multiselect-tags-search {
    border: none !important;
  }
  .multiselect-placeholder {
    font-size: 14px;
    color: #989898;
    padding-left: 10px;
    padding-right: 10px;
  }
  .multiselect.is-active {
    box-shadow: none;
    border-color: transparent !important;
  }
  .multiselect-caret {
    outline: none;
    border-color: unset;
    border-style: unset;
    border-width: unset;
    width: 10px;
    height: 10px;
    border-radius: 1px;
    border-left: 3px solid #343c44;
    border-bottom: 3px solid #343c44;
    transform: rotate(315deg);
    margin-top: -6px;
    transition: 0.3s;
    mask-image: unset;
    cursor: pointer;
    &.is-open {
      transform: rotate(135deg);
      margin-top: 2px;
    }
  }
  .is-visible {
    .multiselect {
      flex-direction: column;
      .multiselect-dropdown {
        position: initial;
        display: block !important;
        transform: none;
        max-height: unset;
        width: 100%;
        border-bottom: none;
        border-left: none;
        border-right: none;
        min-height: 403px;
      }
      .multiselect-search {
        display: block !important;
        position: initial;
        margin-bottom: 1px;
        border: 1px solid transparent;
        &:focus {
          border: 1px solid #01a39d;
        }
      }
      .multiselect-clear {
        position: absolute;
        top: 12px;
        right: 6px;
      }
      .multiselect-caret {
        display: none;
      }
      .multiselect-placeholder,
      .multiselect-multiple-label {
        height: auto;
        left: 6px;
        top: 12px;
      }
    }
  }
  .custom-inMultiselect {
    .multiselect-group-label {
      background: #ffffff;
      border-top: 2px solid #d3dfe5;
      border-bottom: 2px solid #d3dfe5;
      padding: 4px 8px 4px 42px;
      &.is-selected {
        background: var(--ms-group-label-bg-selected, #059669);
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
      }
    }
    .multiselect-checkBox {
      height: 18px;
      width: 18px;
      border: 2px solid #00c6a2;
      border-radius: 2px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &.gradient {
        .multiselect-checkBox__arrow {
          border-left: 2px solid #ffffff;
          border-bottom: 2px solid #ffffff;
        }
      }
      &__arrow {
        display: none;
        height: 4px;
        width: 8px;
        border-left: 2px solid #00c6a2;
        border-bottom: 2px solid #00c6a2;
        transform: rotate(-50deg);
      }
    }
    .multiselect-text {
      width: 90%;
    }
    .multiselect-option.is-selected {
      background: none;
      color: #1f2429;
      .multiselect-checkBox {
        &.gradient {
          border: none;
          background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
        }
        &__arrow {
          display: block;
        }
      }
    }
    .multiselect-img {
      height: 61px;
      width: 61px;
      border-radius: 50%;
      margin-right: 8px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .multiselect-search {
    height: 100%;
  }

  .multiselect-checkbox {
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid $light-grey-5;

    .multiselect-dropdown {
      border-top: none;
      transform: translateY(calc(100% - 1px));
    }

    .multiselect-search {
      font: $fira-14-20;
      padding: 10px 50px 10px 14px;
      border: none !important;
    }

    .multiselect-caret,
    .multiselect-clear {
      margin-right: 20px;
    }

    .multiselect-placeholder {
      padding-left: var(--ms-px, 0.875rem);
      padding-right: 50px;
      font: $fira-14-20;
    }

    .multiselect-multiple-label,
    .multiselect-single-label {
      font: $fira-14-20;
      display: flex;
      gap: 4px;
      color: $color-black;
      padding: 10px 50px 10px 14px;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &__selected-number {
        border-radius: 2px;
        background: $blue;
        color: #fff;
        padding: 0px 8px;
      }
    }

    .option-container {
      display: flex;
      width: 100%;
    }

    .multiselect-option.is-pointed:hover,
    .multiselect-option.is-selected {
      background-color: $grey-disabled !important;
      color: $blue-dark;
    }

    &.error {
      .multiselect-wrapper {
        border-color: $red;
      }
    }

    &.is-active {
      border-color: $grey;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .input-group-wrapper {
      font: $fira-14-20;
    }

    //checkbox
    .input-group-wrapper.checkbox-green {
      .form-check-input {
        display: none;
        opacity: 0;
        margin: 0;
        width: 20px;
        height: 20px;
        z-index: 10;
        cursor: pointer;
      }

      .form-check-label {
        position: relative;
        font-family: 'Fira Sans';
        font: $fira-14-20;
        padding-left: 32px;
        cursor: pointer;

        &::before,
        &::after {
          content: '';
          position: absolute;
          border-radius: 4px;
          border: 1px solid $green;
          width: 20px;
          height: 20px;
          top: 0;
          left: 0;
          z-index: 0;
        }

        &::after {
          opacity: 0;
          border: none;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3e%3crect width='20' height='20' rx='4' fill='url(%23paint0_linear_3530_38638)'/%3e%3cpath d='M15 5.8335L8.125 13.3335L5 9.92441' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cdefs%3e%3clinearGradient id='paint0_linear_3530_38638' x1='20' y1='10' x2='0' y2='10' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%2301A39D'/%3e%3cstop offset='1' stop-color='%230078C8'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
          transition: 0.5s ease;
          z-index: 1;
        }
      }

      input:checked ~ .form-check-label {
        &::after {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
</style>
