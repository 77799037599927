import Api from '@/app/modules/ExportPotential/api/exportpotential';

export default {
  state: {
    // данные для селекта карты потенциала
    exportPotentialMapSelect: null,
    // данные для карты потенциала
    exportPotentialMap: {},
    exportPotentialMapProducts: [],
  },
  getters: {
    getExportPotentialMapSelect(state) {
      return state.exportPotentialMapSelect;
    },
    getExportPotentialMap(state) {
      return state.exportPotentialMap;
    },
    getExportPotentialMapProducts(state) {
      return state.exportPotentialMapProducts;
    },
  },
  mutations: {
    updateExportPotentialMapSelect(state, selectData) {
      state.exportPotentialMapSelect = selectData;
    },
    updateExportPotentialMap(state, mapData) {
      state.exportPotentialMap = mapData;
    },
    updateExportPotentialMapProducts(state, selectData) {
      state.exportPotentialMapProducts = selectData;
    },
  },
  actions: {
    downloadExportPotentialMapSelect(ctx) {
      Api.getProductsListWithExportPotential().then((ret) => {
        let data = ret?.data
          .filter((item) => item.level <= 2 && item.isActive)
          .map((item) => {
            if (item.name && item.code && item.id) {
              return {
                label: `${item.code} - ${item.name}`,
                code: item.code,
                id: item.id,
                value: item.code,
                searchText: item.name,
              };
            }
          });
        const selectData = data.sort((a, b) => a.label.localeCompare(b.label));
        ctx.commit('updateExportPotentialMapSelect', selectData);
        ctx.commit(
          'updateExportPotentialMapProducts',
          ret?.data.map((item) => {
            if (item.name && item.code && item.id) {
              return {
                label: `${item.code} - ${item.name}`,
                code: item.code,
                id: item.id,
                value: item.code,
                searchText: item.name,
              };
            }
          }),
        );
      });
    },
    downloadExportPotentialMap(ctx, request) {
      Api.search(request).then((response) => {
        let activeCountries = {};
        response.data.items.forEach((item) => {
          if (item.toCountry) {
            if (item.toCountry.code) {
              if (activeCountries[item.toCountry.code] !== undefined) {
                activeCountries[item.toCountry.code].exportValue += item.exportValue;
                activeCountries[item.toCountry.code].exportFact += item.exportFact;
                activeCountries[item.toCountry.code].value += item.value;
              } else {
                activeCountries[item.toCountry.code] = {
                  counter: 1,
                  label: item.toCountry.name,
                  exportValue: item.exportValue,
                  exportFact: item.exportFact,
                  value: item.value,
                  name: item.itemsNsiTnved[0].name,
                  code: item.itemsNsiTnved[0].code,
                };
              }
            }
          }
        });
        ctx.commit('updateExportPotentialMap', activeCountries);
      });
    },
    downloadExportPotentialServiceMap(ctx, request) {
      Api.getServiceGraph(request).then((response) => {
        let activeCountries = {};
        response.data.forEach((item) => {
          activeCountries[item.toCountry.code] = {
            counter: 1,
            label: item.toCountry.name,
            exportValue: item.exportValue,
            exportFact: item.exportFact,
            value: item.value,
            name: item.serviceName,
            code: item.serviceCode,
          };
        });
        ctx.commit('updateExportPotentialMap', activeCountries);
      });
    },
  },
};
