<template>
  <GDialog :max-width="maxWidth">
    <div
      :class="['modal__body', { 'modal__body-p0': headType != 'default' }, mod ? `modal__body--${mod}` : '']"
      :style="`max-width: ${maxWidth}px`"
    >
      <!-- modal header -->
      <div :class="'modal__body-head-' + headType" class="modal__body-head" v-if="!disableHead">
        <!-- в компонент можно менять только этот тайтл -->
        <p class="modal__body-head__title">{{ title }}</p>
        <IconComponent @click="$emit('close')" :name="closeIcon" />
      </div>
      <!-- modal body -->
      <div class="modal__body-inner">
        <!-- slot start -->
        <slot />
        <!-- slot end -->
      </div>
    </div>
  </GDialog>
</template>

<script>
  import { GDialog } from 'gitart-vue-dialog';
  import 'gitart-vue-dialog/dist/style.css';

  import IconComponent from './IconComponent.vue';

  export default {
    name: 'ModalComponent',
    components: { IconComponent, GDialog },
    props: {
      closeIcon: {
        type: String,
        default: 'crossInCircle',
        validation: (value) => ['crossInCircle', 'closeCross'].includes(value),
      },
      title: {
        default: '',
        type: String,
      },
      headType: {
        default: 'default',
        type: String,
      },
      disableHead: {
        default: false,
        type: Boolean,
      },
      maxWidth: {
        type: String,
        default: '',
      },
      mod: {
        type: String,
        default: '',
        validation: (value) => ['redesign'].includes(value),
      },
    },
  };
</script>

<style lang="scss">
  // Modal
  .modal {
    &__body {
      padding: 16px;
      background: #ffffff;
      max-width: 800px;
      &-head {
        display: flex;
        justify-content: space-between;
        //margin-bottom: 24px;
        p {
          font-family: 'Fira Sans', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: #000000;
        }
        .icon {
          cursor: pointer;
        }
        &-green,
        &-blue {
          padding: 26px;
          p {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.15px;
            color: #ffffff;
          }
        }
        &-green {
          background-color: #01a39d;
        }
        &-blue {
          background-color: #0179c8;
        }
        // Перечисление всех типов
        &:not(&-blue, &-green) .icon {
          color: $color-grey;
        }
        &-blue .icon,
        &-green .icon {
          color: #ffffff;
        }
      }
      &-p0 {
        padding: 0px;
        .modal__body-inner {
          padding: 26px;
        }
      }
      .form {
        &__body {
          textarea {
            resize: none;
          }
          .select2-container--default .select2-selection--single {
            padding: 0.46875rem 0.5rem;
          }
          textarea,
          input {
            padding: 8px;
          }
          // Stock
          &-inputBlock {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;
            padding-bottom: 40px;
            border-bottom: 1px dashed #d3dfe6;
            .select2 .select2-container--default .select2-selection--single .select2-selection__placeholder {
              color: #787f8f;
            }
            .calendar-input {
              position: relative;
              .icon {
                position: absolute;
                bottom: 10px;
                right: 12px;
                z-index: 1;
              }
            }
            textarea {
              min-height: 72px;
            }
            p {
              font-family: 'Fira Sans', sans-serif;
              font-size: 14px;
              line-height: 160%;
              // color: #231F20;
              margin-bottom: 4px;
              &.title {
                &-subtext {
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.25px;
                  color: #787f8f;
                }
              }
            }
            &:nth-last-child(2) {
              margin-bottom: 52px;
            }
            &:last-of-type {
              margin-bottom: 0px;
              padding-bottom: 0px;
              border-bottom: none;
            }
            button {
              max-width: 220px;
            }
          }
          // Modifated
          &.mpk {
            .form__body {
              &-inputBlock {
                &:nth-of-type(1) {
                  .select2 {
                    max-width: 336px;
                  }
                }
                &:nth-of-type(2) {
                  margin-bottom: 8px;
                }
                &:nth-of-type(3) {
                  display: flex;
                  flex-wrap: wrap;
                  div {
                    margin-right: 8px;
                    &:nth-of-type(1) {
                      max-width: 215px;
                    }
                    &:last-of-type {
                      margin-right: 0px;
                    }
                  }
                  .calendar-input {
                    width: 125px;
                  }
                  .select2 .select2-container--default .select2-selection--single {
                    height: calc(2.25rem + 3px) !important;
                  }
                }
              }
            }
          }
          &.cancel {
            .form__body {
              &-inputBlock {
                p {
                  font-size: 14px;
                  line-height: 160%;
                  color: #231f20;
                  margin-bottom: 4px;
                }
                textarea {
                  width: 100%;
                  min-height: 72px;
                }
              }
            }
          }
        }
      }
      .search {
        &__body {
          padding: 30px 0px 12px 0px;
          text-align: center;
          .icon {
            margin-bottom: 16px;
          }
          .error {
            color: red;
          }
          .success {
            color: green;
          }
          button {
            margin-top: 24px;
          }
        }
      }
      .stock {
        .row {
          display: flex;
          flex-direction: column;
          &:not(:last-of-type) {
            margin-bottom: 24px;
          }
          &-title {
            margin-bottom: 16px;
            font-weight: 500;
          }
          .multiselect {
            &:not(:last-of-type) {
              margin-bottom: 16px;
            }
          }
          .checkboxColumns {
            display: flex;
            flex-wrap: wrap;
            .checkboxInput {
              width: 50%;
              margin-bottom: 8px;
              label {
                padding-top: 2px;
              }
            }
          }
          &.btns {
            flex-direction: row;
            justify-content: flex-end;
            button {
              max-width: 220px;
            }
          }
          textarea {
            height: 100px;
            resize: none;
          }
        }
      }
      .thx {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &-title,
        &-number {
          font-size: 14px;
          line-height: 160%;
          text-align: center;
        }
        &-title {
          font-weight: 500;
          margin-bottom: 8px;
        }
        &-number {
          margin-bottom: 40px;
        }
      }

      &--redesign {
        padding: 0;
        max-width: none;
        max-height: 90vh;
        position: relative;
        overflow: auto;
        -ms-text-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &--redesign & {
        &-head {
          background: #fff;
          position: sticky;
          top: 0;
          left: 0;
          padding: 40px 40px 0;
          z-index: 11;

          &__title {
            font: $fira-24-M;
            margin-bottom: 24px;
            text-transform: unset;
          }
        }

        &-inner {
          position: relative;
          padding: 0 40px 40px;
        }
      }
    }

    @media (max-width: $laptop) {
      &__body {
        &--redesign {
          max-height: 100dvh;
        }

        &--redesign & {
          &-head {
            padding: 24px 14px 0;

            &__title {
              margin-bottom: 16px;
            }
          }

          &-inner {
            padding: 0 14px 24px;
          }
        }
      }
    }
  }
  // Modal
  .g-dialog-content {
    max-width: 100vw;
    max-height: 90vh !important;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }

    @media (max-width: $laptop) {
      max-height: 100dvh !important;
      border-radius: 0;
      margin: 0 !important;
    }
  }
  .g-dialog-overlay {
    background: change-color($black-st, $alpha: 0.4);
  }
  :root {
    --g-dialog-overlay-bg: linear-gradient(270deg, rgba(0, 178, 170, 0.5) 0%, rgba(0, 120, 200, 0.5) 100%);
    --g-dialog-content-border-radius: 8px;
    --g-dialog-content-shadow: 0px 0px 4px 0px #{change-color($black-st, $alpha: 0.5)};
  }
</style>
